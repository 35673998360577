//React
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import Config from "../../../init/Config";
// import Button from "../../../components/button/Button";

//External
import {
  Addons,
  Bubbles,
  scrollReveal,
  snackbarNotification,
  newNotification,
  viewNotification,
  closeNotification,
} from "../../../components/addons/Addons";

import cssGlobal from "../../../components/globalcss/GlobalCSS.module.css";
import Nav from "../../../components/nav/Nav";
import Footer from "../../../components/footer/Footer";

//Main
import css from "./PreLaunch.module.css";

//Images
import background from "../../../images/backgrounds/background.svg";
import iconHosting from "../../../images/icons/hosting.svg";
import iconWiki from "../../../images/icons/wiki.svg";
import iconOffices from "../../../images/icons/offices.svg";
import iconChat from "../../../images/icons/chat.svg";
import splashboardArt from "../../../images/main/splashboard.svg";
import codingclockArt from "../../../images/main/codingclock.svg";
import collaborationArt from "../../../images/main/collaboration.svg";
import ecosystemArt from "../../../images/main/ecosystem.svg";

//Extra
import devUpdatesList from "./devUpdatesList";

export default function Index() {
  document.addEventListener("scroll", function () {
    scrollReveal();
  });

  document.documentElement.setAttribute("data-apptheme", "dark");
  document.body.style.overflow = 'initial';

  var logo = "https://cdn.st.ax/v2/logo.svg";

  var releaseDate = "Aug 2025";



  window.addEventListener('scroll', function() {
    // Get the current scroll position
    var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    // Check if the scroll position is at or beyond 25px
    if (scrollPosition >= 0) {
      document.getElementById("logo-display").style.height = "100dvh";
      document.getElementById("logo-display").style.marginBottom = "60dvh";
    }
    // if (scrollPosition >= 30) {
    //   document.getElementById("logo-display").style.height = "70dvh";
    //   document.getElementById("logo-display").style.marginBottom = "40dvh";
    // }
    if (scrollPosition >= 50) {
      document.getElementById("logo-display").style.height = "20dvh";
      document.getElementById("logo-display").style.marginBottom = "0dvh";
    }

  });

  function appCountdown(){
    const now = new Date();
    const targetDate = new Date('2025-08-31');

    const diffInMilliseconds = targetDate - now; // Difference in milliseconds

    let timeRemaining = '';

    if (diffInMilliseconds > 0) {
      const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
      timeRemaining = `${days} days to go!`;
    } else {
      timeRemaining = "The date has passed!";
    }

    // Create the popup container and popup content dynamically
    const popupContainer = document.createElement('div');
    popupContainer.style.position = 'fixed';
    popupContainer.style.bottom = '20px';
    popupContainer.style.right = '20px';
    popupContainer.style.zIndex = '1000';
    popupContainer.style.display = 'flex';
    popupContainer.style.justifyContent = 'flex-end';

    const popupBox = document.createElement('div');
    popupBox.style.backgroundColor = '#333';  // Dark background
    popupBox.style.color = '#fff';            // White text
    popupBox.style.padding = '20px';
    popupBox.style.borderRadius = '10px';
    popupBox.style.boxShadow = '0px 4px 15px rgba(0, 0, 0, 0.2)';
    popupBox.style.fontFamily = 'Arial, sans-serif';
    popupBox.style.display = 'flex';
    popupBox.style.flexDirection = 'column';
    popupBox.style.alignItems = 'center';
    popupBox.style.position = 'relative';
    popupBox.style.maxWidth = '250px';        // Ensure the popup doesn't get too large
    popupBox.style.textAlign = 'center';      // Center the text

    const popupText = document.createElement('p');
    popupText.innerText = timeRemaining;
    popupText.style.fontSize = '18px';        // Larger, more readable font

    const closeButton = document.createElement('button');
    closeButton.innerText = 'Close';
    closeButton.style.marginTop = '10px';
    closeButton.style.padding = '8px 15px';
    closeButton.style.backgroundColor = '#f44336'; // Red background for button
    closeButton.style.color = '#fff';
    closeButton.style.border = 'none';
    closeButton.style.borderRadius = '5px';
    closeButton.style.cursor = 'pointer';
    closeButton.onclick = () => {
      // Remove the popup after the close button is clicked
      document.body.removeChild(popupContainer);
    };

    // Append the text and button to the popup box
    popupBox.appendChild(popupText);
    popupBox.appendChild(closeButton);

    // Append the popup box to the popup container
    popupContainer.appendChild(popupBox);

    // Append the popup container to the document body
    document.body.appendChild(popupContainer);

    // Automatically remove the popup after 5 seconds
    setTimeout(() => {
      document.body.removeChild(popupContainer);
    }, 5000);
    /*
    if(document.documentElement.getAttribute("data-apptheme") === "dark") {
      document.documentElement.setAttribute("data-apptheme", "light");
    }else {
      document.documentElement.setAttribute("data-apptheme", "dark");
    }
    */
  }



  return (
    <div id="Index-react-div" className={cssGlobal["react-div"]}>
      <div id="Index-react-background" className={cssGlobal["react-background"]}>
         {/* style={{ backgroundImage: `url(${background})` }}> */}
        <div className={cssGlobal["page-full"]}>
          <Addons />
          <Bubbles />
          <div id="logo-display" className={`${css["logo-display"]} ${cssGlobal["flex-center-center"]}`}>
            <div className={css["logo-display-box"]}>
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div id="logo-title" className={`${css["logo-title"]} ${cssGlobal["flex-center-center"]}`}>
            <div className={`${css["landing-nav"]} ${cssGlobal["flex-center-center"]}`}>
              <div className={css["landing-nav-title"]}>
                <p>Stax Developer Studios</p>
              </div>
              <div className={css["landing-nav-text"]}>
                <p>The Ultimate DevOps Ecosystem</p>
              </div>
              <section className={cssGlobal["rs-element-both"]}>
                <button onClick={() => appCountdown()} className={css["landing-finish-date"]}>
                  <p>Coming {releaseDate}</p>
                </button>
              </section>
            </div>
          </div>
          <div className={cssGlobal["content-100"]}>
            <div className={`${css["landing-title"]} ${cssGlobal["flex-center-left"]}`}>
              <div className={css["landing-title-text"]}>
                <h1>
                  A <span className={css["landing-title-text-white"]}>centralised suite of tools</span> for developers to <span className={css["landing-title-text-white"]}>streamline</span> the DevOps workflow
                </h1>
              </div>
              {/* <div className={css["landing-title-text"]}>
                <section className={cssGlobal["rs-element-both-left"]}>
                  <h1>
                    <span className={css["landing-title-text-white"]}>Collaboration and Development Made Easy.</span>
                  </h1>
                </section>
              </div> */}
              <div id="PreLaunch-landing-title-art"className={css["landing-title-art"]}>
                <section style={{perspective: "1500px"}} className={cssGlobal["rs-element-both"]}>
                  <img id="PreLaunch-landing-title-art-image" src={splashboardArt} />
                </section>
              </div>
            </div>
            <div className={`${css["landing-features"]} ${cssGlobal["flex-center-center"]}`}>
              <div className={css["landing-features-box"]}>
                <h1>An ecosystem integrating seamlessly</h1>
                <div className={css["landing-features-box-art"]}>
                  <section className={cssGlobal["rs-element-both"]}>
                    <img id="PreLaunch-landing-feaetures-box-art-img" src={ecosystemArt} />
                  </section>
                </div>
              </div>
              <div className={css["landing-features-box"]}>
                <h1>Enhancing teamwork and collaboration</h1>
                <div className={css["landing-features-box-art"]}>
                  <section className={cssGlobal["rs-element-both"]}>
                    <img id="PreLaunch-landing-feaetures-box-art-img--2" src={collaborationArt} />
                  </section>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={cssGlobal["content-100"]}>
            <div className={css["landing-subtitle"]}>
              <h1>Upcoming Project Timelines</h1>
            </div>
          </div> */}
          <div className={cssGlobal["content-100"]}>
            <div className={css["landing-project"]}>
              <section className={cssGlobal["rs-element-both"]}>
                <div className={`${css["landing-project-title"]} ${cssGlobal["flex-center-left"]}`}>
                  <div className={css["landing-project-title-icon"]}>
                    <section className={cssGlobal["rs-element-both"]}>
                      <img src={iconHosting} alt="logo" />
                    </section>
                  </div>
                  <div className={css["landing-project-title-text"]}>
                    <h1>SDS Hosting</h1>
                  </div>
                  <div className={css["landing-project-title-info"]}>
                    <p>Hosting for anything. Literally.</p>
                  </div>
                </div>
                <div className={css["landing-project-progress"]}>
                  <div style={{width: "86%"}} className={css["landing-project-progress-bar"]}></div>
                </div>
              </section>
              <div className={`${css["landing-project-list"]} ${cssGlobal["flex-flex-start-left"]}`}>
                <div className={css["landing-project-list-box"]}>
                  <section className={cssGlobal["rs-element-both"]}>
                    <div className={`${css["landing-project-list-box-icon"]} ${cssGlobal["flex-center-center"]}`}>
                      <i className={`${css["fas"]} ${css["fa-server"]} ${"fas fa-server"}`}></i>
                    </div>
                    <p>
                      Scalable Droplets that allows you to change your budget and resources <span>on the fly.</span>
                    </p>
                  </section>
                </div>
                <div className={css["landing-project-list-box"]}>
                  <section className={cssGlobal["rs-element-both"]}>
                    <div style={{animationDelay: "1s"}} className={`${css["landing-project-list-box-icon"]} ${cssGlobal["flex-center-center"]}`}>
                      <i className={`${css["fas"]} ${css["fa-gauge=high"]} ${"fas fa-gauge-high"}`}></i>
                    </div>
                    <p>
                      <span>Built-in Dashboard</span> with an AI Chatbot, custom team permissions, server analytics and more...
                    </p>
                  </section>
                </div>
                <div className={css["landing-project-list-box"]}>
                  <section className={cssGlobal["rs-element-both"]}>
                    <div style={{animationDelay: "1.5s"}} className={`${css["landing-project-list-box-icon"]} ${cssGlobal["flex-center-center"]}`}>
                      <i className={`${css["fas"]} ${css["fa-rocket"]} ${"fas fa-rocket"}`}></i>
                    </div>
                    <p>
                      Launch your droplet easily with several popular <span>pre-built templates</span> to choose from.
                    </p>
                  </section>
                </div>
                <div className={css["landing-project-list-box"]}>
                  <section className={cssGlobal["rs-element-both"]}>
                    <div style={{animationDelay: "2s"}} className={`${css["landing-project-list-box-icon"]} ${cssGlobal["flex-center-center"]}`}>
                      <i className={`${css["fas"]} ${css["fa-wand-magic-sparkles"]} ${"fas fa-wand-magic-sparkles"}`}></i>
                    </div>
                    <p>
                      Load balancers, snapshots, custom domains, custom ports and tons more <span>with every server.</span>
                    </p>
                    </section>
                </div>
                <div className={css["landing-project-list-box"]}>
                  <section className={cssGlobal["rs-element-both"]}>
                    <div style={{animationDelay: "2.5s"}} className={`${css["landing-project-list-box-icon"]} ${cssGlobal["flex-center-center"]}`}>
                      <i className={`${css["fas"]} ${css["fa-shapes"]} ${"fas fa-shapes"}`}></i>
                    </div>
                    <p>
                      We aim to accomodate <span>all skill levels.</span> Our services are designed to be <span>simple and easy to use.</span>
                    </p>
                  </section>
                </div>
              </div>
            </div>
            <section className={cssGlobal["rs-element-both"]}>
              <div className={css["whiteboard"]}>
                <div className={css["whiteboard-title"]}>
                  <h2>Introducing...</h2>
                  <h1>THE STAX WHITEBOARD!!!{" "}
                    <i className={`${css["fa-face-smile"]} ${"far fa-face-smile"}`}></i>{" "}
                    <i className={`${css["fa-face-smile"]} ${"far fa-face-smile"}`}></i>{" "}
                    <i className={`${css["fa-face-smile"]} ${"far fa-face-smile"}`}></i>
                  </h1>
                </div>
                <div className={css["whiteboard-info"]}>
                  <p>
                    A productivity tool that allows <span>you to jot down notes, tasks and reminders with the help of Staxle, your very own AI assistant.</span>
                  </p>
                </div>
                <section className={cssGlobal["rs-element-both"]}>
                <div className={`${css["whiteboard-sticky"]} ${cssGlobal["flex-flex-start-center"]}`}>
                  <div className={css["whiteboard-sticky-box"]} contentEditable>
                    <p>Collaborate  in real-time with your team with the <u title="Staxle is seriously awesome!">AI Assistant</u>!!!!</p>
                  </div>
                  <div className={css["whiteboard-sticky-box"]} contentEditable>
                    <p>
                      ..to help you carry out tasks
                      <br/><br/>
                      Like creating this sticky note!
                    </p>
                  </div>
                  <div className={css["whiteboard-sticky-box"]} contentEditable>
                    <p>Finish the <span className={`${css["censor"]}`}>fucking</span> SDS already!!!
                      <br/><br/>
                      - Staxle
                    </p>
                  </div>
                </div>
                </section>
              </div>
            </section>
          </div>

          {/* <div className={cssGlobal["content-100"]}>
            <div className={css["landing-project"]}>
              <div className={`${css["landing-project-title"]} ${cssGlobal["flex-center-left"]}`}>
                <div className={css["landing-project-title-icon"]}>
                  <img src={iconWiki} alt="logo" />
                </div>
                <div className={css["landing-project-title-text"]}>
                  <h1>SDS Wiki</h1>
                </div>
              </div>
              <div className={css["landing-project-progress"]}>
                <div style={{backgroundColor: "var(--accent4)", width: "23%"}} className={css["landing-project-progress-bar"]}></div>
              </div>
              <div className={`${css["landing-project-list"]} ${cssGlobal["flex-flex-start-left"]}`}>
              </div>
            </div>
          </div> */}

          <div className={cssGlobal["content-100"]}>
            <div className={css["landing-updates-art"]}>
              {/* <section className={cssGlobal["rs-element-both"]}> */}
                <img src={codingclockArt}/>
              {/* </section> */}
            </div>
            <div className={css["landing-updates-title"]}>
              <h1>Development Updates</h1>
            </div>
            <div className={css["landing-updates"]}>
              {[...Array(1)].map((number, index) => (
                devUpdatesList.map((list, index) => (
                  <div key={index} className={`${css["landing-updates-box"]} ${cssGlobal["flex-center-left"]}`}>
                    <div className={css["landing-updates-box-line"]}></div>
                    <div className={css["landing-updates-box-circle"]}></div>
                    <div className={css["landing-updates-box-info"]}>
                      <h1>{list.title} • {list.date}</h1>
                      {list.info}
                    </div>
                  </div>
                )).reverse()
              ))}
            </div>
          </div>
          {/* Contact Us */}
          <div className={cssGlobal["content-100"]}>
            {/* <div className={css["landing-contact"]}>
              <h1>Contact Us</h1>
              <div className={`${css["landing-contact-list"]} ${cssGlobal["flex-flex-start-left"]}`}>
                <div style={{borderColor: "#229ED9"}} className={css["landing-contact-list-box"]}>
                  <div className={`${css["landing-contact-list-box-icon"]} ${cssGlobal["flex-center-center"]}`}>
                    <i style={{color: "#229ED9", fontSize: "160%"}} className={`${css["fas"]} ${css["fa-telegram"]} ${"fab fa-telegram"}`}></i>
                  </div>
                  <p>Subscribe to our Telegram Bot for live announcements and updates!</p>
                </div>

              </div>
            </div> */}
        {/* <div className={css["landing-finish"]}>
              <button onClick={() => appTheme()} className={css["landing-finish-date"]}>
                <p>{releaseDate}</p>
              </button>
            </div>
        */}

          </div>
        </div>
      </div>
    </div>
  );
}
