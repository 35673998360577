const devUpdatesList = [
    {
      title: "Is this thing on?",
      date: "1st December 2023",
      info: [
        <p>
            Hey there! Welcome to the SDS <span style={{color: "var(--st11)"}} title="I could just say landing page I guess :/">pre-launch</span> page. 
            SDS is currently in the process of development and more news is yet to come!{" "}
            Updates will be posted here as things progress.<br/><br/>
            We look forward to having you at our release!
        </p>,
      ],
    },
    {
      title: "Now we're cooking!",
      date: "22nd April 2024",
      info: [
        <p>
          Our plans are designed to be as affordable as possible... <span title="yuck - a cliche">by coders, for coders</span>.
          <br/><br/><br/>New developments:
          <ul>
            <li>SDS Mobile App - Manage your SDS ecosystem from your pocket on the go!</li>
            <li>Powerful <span style={{color: "var(--st21)"}}><b>uoIPv7 protocol</b></span> - take the hassle out of DNS with while staying in control of everything. This IPv4 replacement will be baked into our ecosystem so that everything just works like you want it to, no questions asked.</li>
            <li>Personal <span style={{color: "var(--st11)"}} title="...yes, we mean Staxle">AI Assistant</span> included in all services</li>
          </ul>
        </p>
      ]
    },
    {
      title: "Getting warmer...",
      date: "14th September 2024",
      info: [
        <p>
          Introducing the <span style={{color: "var(--text1)"}}>Whiteboard</span>. An extra productivity tool to manage and collaborate work efficiently.{" "}
          <br/><br/>
          Say hello to <span style={{color: "var(--st31)"}}><b>Staxle</b></span>!. The AI assistant that doesn't just chat with you.{" "}
          Staxle can carry out <span style={{color: "var(--text1)"}}>real operations</span> and task like creating servers, updating details, manging your whiteboard/dashboard, and a whole lot more. Autopilot is finally a reality.
        </p>
      ]
    },
    {
      title: "Alpha Testing",
      date: "5th December 2024",
      info: [
        <p>
          We plan to launch the extraordinary SDS <span style={{color: "var(--text1)"}}>next year</span>.{" "}
          The final stages of development are coming together.{" "}
          Alpha testing is underway and we will release the platform for beta testing very soon.<br/>
          Updates:
          <ul>
            <li><span style={{color: "var(--st11)"}}>Subdomains</span> provided for servers</li>
            <li>3rd party app integrations</li>
            <li>Open source code for transparency</li>
          </ul>
        </p>
      ]
    }
  ];

  export default devUpdatesList;
